.tab-card-title {
  font-weight: 700 !important;
  display: block !important;
  float: right;
  padding: 15px;
  font-size: 1.3rem;
}
.tab-inner-grid-attr {
  font-weight: 700 !important;
  font-size: 1.1rem;
  display: inline !important;
  padding-right: 1px;
}
.highlighted-title {
  color: white;
  font-weight: 700 !important;
  font-size: 1.1rem;
  background-color: #3447ad;
  outline: none !important;
  display: flex;
  padding: 16px;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  /* margin-top: 20px; */
}

.yellow-color {
  color: #196938 !important;
}
.edit-btn{
  background-color: #fcae31 !important
}
.danger-btn{
  background-color: #ce3342 !important
}
.green-btn{
  background-color: #116825 !important

}
.type-badge{
  color: #fcae31 !important;
  height: 28px !important;
  min-width: 90px;
  max-width: 146px;
}

.ql-container.ql-bubble.ql-disabled {
 font-size: large !important;
}