.inquiry-form {
  /* justify-content: center;
    display: flex; */
  /* margin-top: 50%;
    margin-bottom: 50%; */
}
.inquiry-btn {
  /* width: 100px; */
  height: 40px;
  /* margin-top: 20px; */
}
.inp-w input {
  background-color: white;
  width: 100%;
}
.grid-up {
  width: 50%;
}
.inp-w {
  width: 60%;
}
.modal-style {
  top: 40%;
  left: 40%;
  transform: translate(-40%, -40%);
  border-radius: 2%;
  position: absolute;
  width: 450px;
  height: 300px;
  background-color: white;
  padding: 16px 32px 24px;
}
.modal-style > *{
    text-align: center;
}
.modal-btn{
    width: 30%
}
/* .btn-m{
    margin-top: 50px
} */
/* .modal-icon {
    color: green;
     width: 60px;
      height: 60px ;
} */
