.root {
  width: 100%;
}

.children {
  min-height: 90vh;
}

.icon {
  height: 40px;
  width: 40px;
}

.stepper {
  background-color: #f4f5fd !important;
}
.active-step {
  color: #196938 !important;
}
.accordion-shadow {
  box-shadow: -2px -1px 20px #e7e7e7;
  border-radius: 10px;
}
.txt-color {
  color: #4c5db7;
}
.input-w {
  width: 30%;
  /* margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px; */
}
hr {
  border: 0;
  clear: both;
  display: block;
  width: 96%;
  background-color: #e1dddd;
  height: 1px;
}
/* .MuiButton-label { */
/* font-size: 10px;
  font-weight: 900; */
/* } */
.preview-btn-size {
  height: 35px;
}
.disabled {
  color: rgba(0, 0, 0, 0.6) !important;
  background-color: #e6e3e3 !important;
}
.camera-button {
  height: 34px;
  font-size: 0.8em;
  padding: 0 !important;
  min-width: 17%;
  padding-left: 4px !important;
  background-color: white;

  align-items: center;
  color: #3c44b1;
}
.ql-editor {
  height: 290px !important;
}
