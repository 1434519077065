.buttonStyle {
  width: 100%;
  /* height: 20vh; */
  color: #3b3e66 !important;
}

.buttonStyle:hover {
  color: white !important;
}
.gridStyle {
  min-width: 12vw;
  height: 10% !important;
}
.textSize {
  font-size: 1.09em !important;
}
.discFontSize {
  font-size: 0.78em !important;
}
.iconSize {
  font-size: 4.5em;
}
@media (min-width: 768px) and (max-width: 850px) {
  .iconSize {
    font-size: 3.5em;
  }
}
