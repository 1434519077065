.btnStyle {
   background-color: #3c44b1;
   width: 140px;
   height: 45px;
   border-radius: 5%;
   color: white;
   font-weight: bold;
   border: none;
   margin-bottom: 7px; 
   margin-inline-start: auto;
   box-shadow: 0 0.313rem 0.8rem rgb(122 123 151 / 50%), 0 0.126rem 0.225rem rgb(122 123 151 / 30%);
} 

.btnStyle:hover {
  transform: translateY(-2px);
  transition-duration: 0.2s;
}

.toolbar-btn {
  transition-duration: 0.4s !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.toolbar-btn:hover {
  background-color: #a1a4c4 !important;
  color: white;
}

.css-1t5wrdm-MuiDataGrid-filterForm {
  direction: ltr !important;
  flex-direction: row-reverse;
}

.MuiDataGrid-panel.css-n3z9fz-MuiPopper-root-MuiDataGrid-panel {
  inset: 0px auto auto 625px !important;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle{
  font-weight: 900 !important;
}