//  Core

.__floater {
  z-index: 1400 !important;
}

.__floater__body {
  button {
    @include border-radius($border-radius !important);
    outline: none !important;
  }
}

.joyride-button {
  @include border-radius($btn-border-radius !important);
}

.joyride-step__container {
  @include border-radius($card-border-radius !important);
  box-shadow: $modal-content-box-shadow-xs !important;
}
