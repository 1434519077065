// Core

@import 'core';

// Fonts

@import url('https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mirza:wght@400;500;600;700&display=swap');

// Mada Font

// Widgets

@import 'widgets';

// Components

@import 'components';

// Layout Components

@import 'layout-components';

// Layout Blueprints

@import 'layout-blueprints';

// * {
//   direction: rtl;
// }
.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}
.rtl {
  direction: rtl;

  unicode-bidi: bidi-override !important;
  direction: unset !important;
  text-align: right;
}
.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
@font-face {
  font-family: 'Mada';
  font-style: normal;
  font-weight: normal;
  // src: local('Mada'), url('./fonts/Mada/Mada-Regular.ttf') format('truetype');
}
