.root {
  width: 100%;
}

.children {
  min-height: 90vh;
}

.icon {
  height: 40px;
  width: 40px;
}

.stepper {
  background-color: #f8f9ff !important;
}
.active-step {
  color: #196938 !important;
}
.accordion-shadow {
  box-shadow: -2px -1px 20px #e7e7e7;
  border-radius: 10px;
}
.txt-color {
  color: #4c5db7;
}
.input-w {
  width: 30%;
  /* margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px; */
}
hr {
  border: 0;
  clear: both;
  display: block;
  width: 96%;
  background-color: #e1dddd;
  height: 1px;
}
/* .MuiButton-label { */
/* font-size: 10px;
  font-weight: 900; */
/* } */
.preview-btn-size {
  height: 35px;
}
.disabled {
  color: rgba(0, 0, 0, 0.6) !important;
  background-color: #e6e3e3 !important;
}
.camera-button {
  height: 34px;
  font-size: 0.8em;
  padding: 0 !important;
  min-width: 17%;
  padding-left: 4px !important;
  background-color: white;

  align-items: center;
  color: #3c44b1;
}
.ql-editor {
  height: 290px !important;
}

.radio-toolbar {
  margin: 10px;
}

.radio-toolbar input[type='radio'] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
  display: inline-block;
  /* background-color: #4caf50; */
  padding: 10px 20px;
  font-family: sans-serif, Arial;
  font-size: 16px;
  border-radius: 4px;
}

/* .radio-toolbar label:hover {
  background-color: #dfd;
} */

/* .radio-toolbar input[type='radio']:focus + label {
  border: 2px dashed #444;
} */

.radio-toolbar .btnSelect input[type='radio']:checked + .btn-decline,
.radio-toolbar .btnSelect input[type='radio']:checked + .btn-sm-decline {
  color: white;
  background-color: #d83428;
  opacity: 10;
 
}
.radio-toolbar .btnSelect input[type='radio']:checked + .btn-renew,
.radio-toolbar .btnSelect input[type='radio']:checked + .btn-sm-renew {
   background-color: #4caf50;
  color: white;
  opacity: 10;
}

.radio-toolbar .btnSelect input[type='radio']:checked + .btn-sm-continue{
  background-color: #fcae31;
  color: white;
  opacity: 10;
}

.btnSelect {
  display: inline-block;
  margin-left: 10px;
}
.label-center {
  text-align: center;
}

/* ################ */
.btn-decline {
  border: 2px solid red;
  color: white;
  background-color: #d83428;
  opacity: .5;
  width: 156px;
}
.btn-decline:hover {
  color: white;
  background-color: #d83428;
  opacity: 10;
}

.btn-sm-decline {
  border: 2px solid red;
  color: white;
  background-color: #d83428;
  opacity: .5;
  width: 70px;
}
.btn-sm-decline:hover {
  color: white;
  background-color: #d83428;
  opacity: 10;
}

/* ################## */
.btn-renew {
  border: 2px solid #4c4;
  background-color: #4caf50;
  color: white;
  opacity: .5;
  width: 156px;
}
.btn-renew:hover {
  color: white;
  background-color: #4caf50;
  opacity: 10;
}
.btn-sm-renew {
  border: 2px solid #4c4;
  background-color: #4caf50;
  color: white;
  opacity: .5;
  width: 70px;
}
.btn-sm-renew:hover {
  color: white;
  background-color: #4caf50;
  opacity: 10;
}

/* ################ */
.btn-sm-continue{
  border: 2px solid #fcae31;
  background-color: #fcae31;
  color: white;
  opacity: .5;
  width: 70px;
}
.btn-sm-continue:hover {
  background-color: #fcae31;
  color: white;
  opacity: 10;
}
