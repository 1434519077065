
  
  .timeline-list-horizontal .timeline-item .timeline-item--content {
    text-align: center;
    margin-top: 17px;
  }
  .timeline-list-horizontal .timeline-item .timeline-item--icon {
    top: -5px !important;
    left: 50%;
    margin-left: calc((100% / 2) - 50px) !important;
  }
  
  .timeline-element-margin {
    margin-right: 20px !important;
  }
  
  .line {
    content: '';
    top: 38px !important;
    height: 4px;
    /* border-radius: 0.65rem; */
    background: #3c44b1 !important;
  }
  
  .white-space {
    width: 50px;
    height: 116px;
    display: block;
    background-color: transparent;
    flex: none;
  }

  