.MuiCardHeader-title {
  float: right;
  font-weight: 600 !important;
}
.semi-bold {
  font-weight: 500;
}
.MuiTypography-h5 {
  font-size: 1.4rem;
  font-family: Heebo, sans-serif;
  font-weight: 400;
  line-height: 1.334;
}
.MuiGrid-grid-md-3 {
  flex-grow: 0;
  max-width: 25%;
  flex-basis: 25%;
  padding-bottom: 15px !important;
}
.bg-color-danger {
  background-color: #fdb2b9b3;
}
.txt-danger {
  color: red;
}
