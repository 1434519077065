.bg-img {
  height: 100% !important;
  background-image: url('../../assets/images/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.loginBtn {
  color: #f5f070 !important;
  background-color: #253053 !important;
  border-radius: 10px !important;
  border: 1px solid #f5f070 !important;
}
.loginBtn:active {
  color: white !important;
  background-color: #fcae31 !important;
  border: 1px solid #fcae31 !important;
}
.loginBtn:hover {
  color: white !important;
  background-color: #fcae31 !important;
  border: 1px solid #fcae31 !important;
}
.text-danger {
  color: #ed0b21;
}
.form {
  width: 100%;
}
.form-bg {
  background-color: #253053;
  margin: 0 auto;
  width: 100%;
}
.card-width {
  width: 50wh;
}
.grid-height {
  height: 100%;
}
/* p::before {
  float: left;
  width: calc(100% - 15em);
  height: 1em;
  content: '';
} */

@font-face {
  font-family: 'ArabicKufiSSk';
  src: url('../../assets/fonts/ArabicKufiSSk/ArabicKufi.ttf') format('truetype');
}

.buorg-test {
  display: none;
}