.modal {
  direction: ltr !important;
}

.modal div {
  direction: ltr !important;
}

.modal canvas {
  direction: ltr !important;
}

.modal span {
  direction: ltr !important;
}
