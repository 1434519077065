.expand-icon {
  color: white;
}
.expand-content-text {
  color: #3447ad;
}
.expand-button {
  text-align: left !important;
}
.MuiSwitch-root.switch-medium .MuiSwitch-switchBase {
  margin-right: 28px;
}
.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track {
  background-color: #e7b1b1 !important;
  border-color: #e7b1b1 !important;
}

.MuiSwitch-root > .Mui-disabled.Mui-checked + .MuiSwitch-track {
  background: #b8dec1 !important;
  border-color: #b8dec1 !important;
}
.MuiSwitch-root .MuiSwitch-track:hover {
  border: 0 !important;
}

.MuiSwitch-colorSecondary + .MuiSwitch-track {
  background-color: #eb5151 !important;
  border-color: #eb5151 !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #28bd4b !important;
  border-color: #28bd4b !important;
}
.bg-red {
  background-color: #a10d0d !important;
}
