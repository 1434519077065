@import "../../assets/base.scss";

.root {
  width: 100%;
}

.children {
  min-height: 90vh;
}

.icon {
  height: 40px;
  width: 40px;
}
.title {
  position: absolute;
  background-color: white;
  top: -16px;
  left: 12px;
  padding: 1px;
  color: #d4d3d8;
}

.stepper {
  background-color: #f4f5fd !important;
}
// .active-step {
//   color: $gold !important;
// }
.accordion-shadow {
  box-shadow: -2px -1px 20px #e7e7e7;
  border-radius: 10px;
}
.txt-color {
  color: #4c5db7;
}
.input-w {
  width: 30%;
  /* margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px; */
}
.card-title {
  position: absolute;
  background-color: white;
  top: -16px;
  left: 12px;
  padding: 1px;
  color: #d4d3d8;
}
hr {
  border: 0;
  clear: both;
  display: block;
  width: 96%;
  background-color: #e1dddd;
  height: 1px;
}
/* .MuiButton-label { */
/* font-size: 10px;
  font-weight: 900; */
/* } */
.preview-btn-size {
  height: 35px;
}
.disabled {
  color: rgba(0, 0, 0, 0.6) !important;
  background-color: #e6e3e3 !important;
}
.camera-button {
  height: 34px;
  font-size: 0.8em;
  padding: 0 !important;
  min-width: 17%;
  padding-left: 4px !important;
  background-color: white;

  align-items: center;
  color: #3c44b1;
}
.title-label {
  color: #b5b5bb;
}
.header {
  margin-right: 10px;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 1.1rem;
  color: #0f0e0c;
  width: 100% !important;
}
.data {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  color: #0f0e0c;
}
.CardDetail {
  position: relative;
  padding: 15px 12px 12px;
  background: #ffffff;
  border: 2px solid #d4d3d8;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 20px;
}
.ql-editor {
  height: 290px !important;
}

.radio-toolbar {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radio-toolbar input[type="radio"] {
  opacity: 1;
  /* position: fixed; */
}

.radio-select label {
  display: inline-block;
  /* background-color: #4caf50; */
  font-size: 16px;
  border-radius: 4px;
  padding-right: 8px;
}

.checkBox-checked {
  border-radius: 5px;
  border: 0.12rem solid #ba8428;
  color: #ba8428;
  margin-left: 2px !important;
}
// .checkBox-unchecked {
//   border-radius: 5px;
//   background-color: #f3f4fc;
//   color: #6b6b6f;
// }
.input {
  background-color: white !important;
  text-indent: 10px;
  border-radius: 5px !important;
  height: 1.5em !important;
  border: 2px solid #d4d3d8 !important;
}
.inputDate {
  background-color: white !important;
  text-indent: 10px;
  border-radius: 5px !important;
  height: 2.1em !important;
  border: 2px solid #d4d3d8 !important;
}
.documentTypography {
  background-color: white;
  border: 1px solid #dcd7d7;
  border-radius: 0.4rem 0 0 0.4rem !important;
  padding: 6px 0 6px;
  text-indent: 10px;
  min-width: 70% !important;
  max-width: 70% !important;
  color: #a09d9d;
  font-size: 0.7em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.upload-button {
  padding: 7px 24px !important;
  border-radius: 0 5px 5px 0 !important;
  font-size: 0.8rem !important;
}
.upload-button:hover {
  margin: 0 !important;
}

.upload-button span {
  font-style: normal;
  font-weight: bold;
  font-size: 0.8rem !important;
  line-height: 20px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}
.submit {
  border-radius: 2px !important;
  color: #ffffff;
  background-color: #b68537 !important;
  border: none;
  margin-right: 30px;
  border-radius: 5px;
  width: 132px;
  font-family: Montserrat !important;
}
.content {
  margin-top: 30px;
}
.swal-wide {
  width: 534px;
  height: 242px;
  border-radius: 6px;
  font-family: Montserrat !important;
}
.swal2-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 1.8em 1em 0;
  color: #0f0e0c;
}
.swal2-container {
  z-index: 20000 !important;
}
.cancel {
  border-radius: 2px !important;
  color: #b68537 !important;
  background-color: #ffffff !important;
  border: 2px solid #b68537 !important;
  border-radius: 5px;
  width: 132px;
  font-family: Montserrat !important;
}

.add-element-btn {
  border: 1px solid;
  width: 140px;
  padding: 3px;
  margin: 30px 0;
}
// .step:hover .MuiStepLabel-iconContainer div {
//   background-color: $gold !important;
// }
.step:hover {
  cursor: pointer !important;
  color: white !important;
}
.stepper .MuiStep-root .MuiStepLabel-root:hover {
  cursor: pointer !important;
}
.display-inline-block {
  display: inline-block;
}
.bg-color-gray {
  background-color: #f4f5fd !important;
}
.form-style {
  background-color: white !important;
  border-radius: 20px;
  padding-top: 5%;
  padding-right: 3%;
}
.MuiStepConnector-vertical {
  margin-left: 19px;
}
.header {
  font-weight: bold !important;
  font-size: 28px !important;
  line-height: 34px !important;
  color: black;
}
.input-label-font {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
}

.action-button {
  border-radius: 5px;
  // border: 2px solid $gold !important;
  display: block !important;
  margin-top: 10px !important;
  // color: $gold !important;
  font-weight: 500 !important;
}
.text-align-left {
  text-align: left;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.color-red {
  color: red;
}
.center-items {
  justify-content: center;
}
