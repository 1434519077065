.comment {
  width: 100% !important;
  background-color: #ecf0fe;
  height: 100%;
  border-radius: 7px;
  padding: 10px;
  text-align: right;
}
.comment-input {
  width: 100% !important;
}
