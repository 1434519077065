.file-uploader {
  font-family: sans-serif;
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;

  margin-bottom: 20px;
}

.accept {
  border-color: #107c10 !important;
}

.reject {
  border-color: #d83b01 !important;
}
.dzu-inputLabelWithFiles {
  margin-right: 4% !important;
  margin-bottom: 20px !important;
}
.dzu-previewButton {
  margin-right: 10px;
}
.dzu-inputLabel {
  color: #3c44b1;
}
.dzu-dropzone {
  margin: 0;
}
.remove-file:hover,
.remove-img:hover {
  cursor: pointer;
}
.file-name {
  overflow: hidden;
  max-width: 90%;
  min-width: 30%;
  text-indent: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.remove-img {
  position: absolute;
  top: 0;
  right: 0;
}
.multi-upload-btn {
  background-color: #3c44b1;
  width: 100%;
  display: inline-block;
}
.multi-upload-btn:hover {
  cursor: pointer;
}
