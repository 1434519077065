.custom-form-control {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 1;
}
.Desc {
  transform: rotateZ(90deg);
}
.Asc {
  transform: rotateZ(270deg);
}
.txt-color {
  color: rgb(127 127 144);
}
.inp-search {
  width: 80%;
}
.inp-search div {
  height: 30px;
}
td::before {
  border: 0 !important;
}
.pagination-color:hover,
.pagination-color:active {
  /* background-color: #3347ad; */
  /* border-color: #3347ad; */
  /* border-radius: 2%; */
}
.prev-next-border {
  border: 1px solid #f2f2f7;
  padding: 5px 20px;
  margin-right: 10px;
  border-radius: 10%;
}
.ant-pagination-item-active {
  font-weight: 400;
  background: #3347ad !important;
  border-color: #3347ad !important;
  border-radius: 5% !important;
  color: white;
}
.ant-pagination-item {
  border-radius: 20% !important;
}
.prev-next-border:hover,
.prev-next-border:active {
  border: 1px solid #4191ff;
}
.table-container {
  overflow-x: auto;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  padding: 10px;
  margin: 10px;
}
.align-center {
  margin: 0 auto;
}
.m-top {
  margin-top: 10%;
}

tr:hover {
  cursor: pointer;
}
.edit-icon:hover {
  cursor: pointer;
}
.edit-icon:hover,
.edit-icon:active {
  background-color: #3d49b1;
  color: #e5dada;
}
.table tbody .color_code_success td::before {  
  background-color: rgb(146, 230, 146) !important;
  border-radius: 0% !important;
}
.table tbody .color_code_danger td::before {  
   background-color: #ff0b0b83 !important;
   border-radius: 0% !important;
}
.table tbody .color_code_warning td::before {  
  background-color: #fdff6ed5 !important;
  border-radius: 0% !important;
}
.sticky-header{  
  position: sticky !important;
  right: 0 !important; 
  z-index: 1000 !important;
  text-align: center !important;
  background-color: ghostwhite !important;
}
.sticky{  
  position: sticky !important;
  right: 0 !important; 
  z-index: 1000 !important;
  text-align: center !important;
  background-color: white !important;
}