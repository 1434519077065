.content {
  background: #fafafa;
  border-radius: 0px 0px 6px 6px;
  margin-top: 0;
}

.shadow-none {
  box-shadow: none;
  border: none;
}

.comments-btn {
  border: 2px solid black !important;
  border-radius: 5px !important;
}

.username-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  align-items: center;

  color: #1e2337;
}

.role-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-top: 5px;
  align-items: center;

  color: #6a6d7c;
}

.comment-font {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.step-font {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.date-font {
  font-family: Montserrat;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  float: right;
}